import React from 'react';
import logo from '../../../assets/logo_horizontal.svg';
import './styles.css';
import { ViewType } from './typings';

interface MainWrapperProps {
    viewType?: ViewType;
    businessId?: string;
    children?: JSX.Element | JSX.Element[];
}

export const MainWrapper = (props: MainWrapperProps): JSX.Element => (
    <div className="mainWrapper">
        <div>{props.children}</div>
        <div className="logoWrapper">
            <img src={logo} alt="logo" className="logo" />
        </div>
    </div>
);
