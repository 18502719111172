import React from 'react';
import { Badge, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CartImage from '../../../assets/cart.svg';
import './style.css';
import { getNumberOfItems } from '../../services/cartClient';

type CartSymbolWrapperProps = {
    path: string;
};

export const CartSymbol = (props: CartSymbolWrapperProps): JSX.Element => {
    const history = useHistory();
    const onClick = () => history.push(props.path);
    const count = getNumberOfItems();
    return (
        <>
            <Image className="cartPosition cartImage shadow-lg" src={CartImage} onClick={onClick} />
            {count && (
                <Badge bg="danger" pill className="badge">
                    {count}
                </Badge>
            )}
        </>
    );
};
