import { BusinessId, OrderId } from './common/commonTypings';

export const routes = {
    employees: '/:businessId/employees',
    payerDetails: '/:businessId/payerDetails',
    paymentDetails: '/:businessId/paymentDetails',
    thankYou: '/:businessId/thankyou',
    transactionError: '/:businessId/transactionError',
    qrMenu: '/:businessId/menu',
    cart: '/:businessId/cart',
    cartPaymentDetails: '/:businessId/cartPaymentDetails',
    cartPayerDetails: '/:businessId/cartPayerDetails',
    menuItemDetails: '/menuItem',
    activateAccount: '/customer/activate/:uid/:token',
    serviceOrder: '/:businessId/serviceOrder',
};

export const apiEndpoints = {
    business: (businessId: BusinessId): string => `businesses/${businessId}/`,
    employeeList: (businessId: BusinessId): string => `businesses/${businessId}/employees`,
    qrMenuCategories: (businessId: BusinessId): string => `businesses/${businessId}/categories`,
    payment: `anonymous-orders/`,
    serviceOrder: `anonymous-service-orders/`,
    privacyPolicy: 'regulations/privacy-policy/',
    termsAndConditions: 'regulations/terms-and-conditions/',
    giveawayConditions: 'regulations/giveaway-conditions/',
    accountActivation: 'activation/',
    leaveComment: (orderId: OrderId): string => `anonymous-orders/${orderId}/`,
    getMenuOrder: (orderId: string): string => `anonymous-service-orders/${orderId}/`,
};
