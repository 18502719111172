import React, { MouseEventHandler } from 'react';
import { Card } from 'react-bootstrap';
import './styles.css';

export const CategoryChip = ({
    title,
    amount,
    selected,
    onClick,
}: {
    title: string;
    amount: number;
    itemId: string;
    selected: boolean;
    onClick?: MouseEventHandler<HTMLElement>;
}): JSX.Element => (
    <Card
        className={`mb-3 shadow categoryChipCard ${selected ? 'chipSelected' : ''}`}
        tabIndex={0}
        onClick={onClick}
    >
        <div>
            <div>
                <strong>{title}</strong> ({amount})
            </div>
        </div>
    </Card>
);
