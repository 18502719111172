import { toast } from 'react-toastify';
import { apiEndpoints } from '../../settings';
import { apiClient } from '../../common/services/apiClient';
import { AccountActivationData, AccountActivationRequest } from './typings';

interface ActivateAccountServices {
    activateAccount: (accountActivationData: AccountActivationData) => Promise<void>;
}

export const useActivateAccountServices = (): ActivateAccountServices => {
    const activateAccount = (accountActivationData: AccountActivationData): Promise<void> => {
        const url = `${apiEndpoints.accountActivation}`;

        const request: AccountActivationRequest = {
            token: accountActivationData.token,
            uid: accountActivationData.uid,
        };

        return apiClient
            .post<void>(url, request)
            .then(() => {
                toast.success('Konto aktywowane!. Możesz się teraz zalogować.', {
                    autoClose: false,
                });
            })
            .catch(error => {
                toast.error(error);
            });
    };

    return { activateAccount };
};
