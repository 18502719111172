const CART_NAME = 'SERVAL_CART';

export interface CartItem {
    id: string;
    photo?: string;
    price: number;
    count: number;
    name: string;
}

export interface CartData {
    businessId: string;
    tableNumber?: string;
    totalPrice: string;
    items: CartItem[];
}

export const getCart = (): CartData | undefined => {
    const cartString = localStorage.getItem(CART_NAME);
    if (cartString) {
        return JSON.parse(cartString);
    }
    return undefined;
};

export const getCartItemCount = (id: string): number => {
    const cart = getCart();
    const count = cart?.items.find(i => i.id === id)?.count;
    return count ?? -1;
};

export const addToCart = (businessId: string, item: CartItem): CartData => {
    const cart = getCart();
    if (!cart || cart.businessId !== businessId) {
        const newCart = {
            businessId,
            totalPrice: (item.price * item.count).toFixed(2),
            items: [item],
        };
        localStorage.setItem(CART_NAME, JSON.stringify(newCart));
        return newCart;
    }

    const index = cart.items.findIndex(i => i.id === item.id);
    if (index === -1) {
        cart.items.push(item);
    } else {
        cart.items[index].count = item.count;
    }

    cart.totalPrice = cart.items.reduce((acc, i) => acc + i.count * i.price, 0).toFixed(2);
    localStorage.setItem(CART_NAME, JSON.stringify(cart));

    return cart;
};

export const removeFromCart = (id: string): CartData | undefined => {
    const cart = getCart();
    if (cart) {
        cart.items = cart.items.filter(item => item.id !== id);
        cart.totalPrice = cart.items.reduce((acc, i) => acc + i.count * i.price, 0).toFixed(2);
        localStorage.setItem(CART_NAME, JSON.stringify(cart));
    }
    return cart;
};

export const addTableNumber = (tableNumber: string): CartData | undefined => {
    const cart = getCart();
    if (cart) {
        cart.tableNumber = tableNumber;
        localStorage.setItem(CART_NAME, JSON.stringify(cart));
    }
    return cart;
};

export const clearCart = (): void => {
    localStorage.removeItem(CART_NAME);
};

export const getNumberOfItems = (): number | undefined => {
    const cart = getCart();
    return cart?.items.length;
};
