import React from 'react';
import { Image } from 'react-bootstrap';
import CreditCard from '../../assets/credit-card.svg';
import Clock from '../../assets/clock.svg';
import Users from '../../assets/users.svg';
import CheckCircle from '../../assets/check-circle.svg';

type PreparationStatusProps = {
    status?: string;
};

export const PreparationStatus = (props: PreparationStatusProps): JSX.Element => {
    let icon;
    let text;
    if (props.status === 'UNPAID') {
        icon = CreditCard;
        text = 'Nieopłacone';
    } else if (props.status === 'PAID') {
        icon = CreditCard;
        text = 'Opłacone';
    } else if (props.status === 'PREPARING') {
        icon = Clock;
        text = 'W trakcie realizacji';
    } else if (props.status === 'READY') {
        icon = Users;
        text = 'Odebrane przez obsługę';
    } else if (props.status === 'FINISHED') {
        icon = CheckCircle;
        text = 'Dostarczone';
    }
    return (
        <div className="preparationStatusRoot">
            <Image src={icon} alt="logo" />
            <div className="px-2">{text}</div>
        </div>
    );
};
