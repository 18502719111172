import { apiClient } from '../../common/services/apiClient';
import { apiEndpoints } from '../../settings';
import { BusinessId } from '../../common/commonTypings';
import { MenuCategory, MenuCategoryResponse, MenuItem, MenuItemResponse } from './typings';

interface QrMenuServices {
    getCategories: (businessId: BusinessId) => Promise<MenuCategory[]>;
}

export const useQrMenuServices = (): QrMenuServices => {
    const transformResponseToMenuItem = (menuItemResponse: MenuItemResponse): MenuItem => ({
        id: menuItemResponse.id,
        name: menuItemResponse.name,
        description: menuItemResponse.description,
        photo: menuItemResponse.photo,
        price: menuItemResponse.price,
    });

    const transformResponseToCategory = (categoryResponse: MenuCategoryResponse): MenuCategory => ({
        id: categoryResponse.id,
        name: categoryResponse.name,
        order: categoryResponse.order,
        items: categoryResponse.services.map(transformResponseToMenuItem),
    });

    const getCategories = (businessId: BusinessId): Promise<MenuCategory[]> =>
        apiClient
            .get<MenuCategoryResponse[]>(apiEndpoints.qrMenuCategories(businessId))
            .then(({ data }) => data.map(transformResponseToCategory));

    return { getCategories };
};
