import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export const usePageTracking = (): void => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize('UA-224377285-1');

        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
};
