import { apiClient } from '../../common/services/apiClient';
import { apiEndpoints } from '../../settings';
import { LeaveCommentRequest, LeaveCommentResponse } from './typing';

interface ThankYouServicesServices {
    leaveComment: (data: LeaveCommentRequest) => Promise<LeaveCommentResponse>;
}

export const useThankYouServices = (): ThankYouServicesServices => {
    const leaveComment = (data: LeaveCommentRequest): Promise<LeaveCommentResponse> =>
        apiClient
            .patch<LeaveCommentResponse>(apiEndpoints.leaveComment(data.orderId), data)
            .then(_ => ({}));

    return { leaveComment };
};
