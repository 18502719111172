import { BusinessId } from '../../common/commonTypings';
import { apiClient } from '../../common/services/apiClient';
import { apiEndpoints } from '../../settings';
import { Employee, EmployeeResponse } from './typings';

interface EmployeeListServices {
    getEmployeeList: (businessId: BusinessId) => Promise<Employee[]>;
}

export const useEmployeeListServices = (): EmployeeListServices => {
    const transformResponseToEmployee = (employeeResponse: EmployeeResponse): Employee => ({
        id: employeeResponse.id,
        displayName: employeeResponse.display_name,
        avatarPhoto: employeeResponse.avatar_photo,
    });

    const getEmployeeList = (businessId: BusinessId): Promise<Employee[]> =>
        apiClient
            .get<EmployeeResponse[]>(apiEndpoints.employeeList(businessId))
            .then(({ data }) => data.map(transformResponseToEmployee));

    return { getEmployeeList };
};
