import { Button, ButtonGroup, FormText } from 'react-bootstrap';
import React, { useState } from 'react';
import './styles.css';

type CounterProps = {
    onChange: (count: number) => void;
    border?: boolean;
    shadow?: boolean;
    size?: 'small';
    start?: number;
};

export const Counter = (props: CounterProps): JSX.Element => {
    const [count, setCount] = useState<number>(props.start ?? 1);
    const decreaseCount = () => {
        const newCount = Math.max(1, count - 1);
        setCount(newCount);
        props.onChange(newCount);
    };
    const increaseCount = () => {
        const newCount = Math.min(9, count + 1);
        setCount(newCount);
        props.onChange(newCount);
    };

    return (
        <ButtonGroup className={`counterRoot p-0 ${props.shadow && 'shadow'}`}>
            <Button
                onClick={decreaseCount}
                variant="light"
                className={`countButton ${props.border && 'border-start border-top border-bottom'}`}
            >
                -
            </Button>
            <FormText className={`count ${props.border && 'border-top border-bottom'}`}>
                {count}
            </FormText>
            <Button
                onClick={increaseCount}
                variant="light"
                className={`countButton ${props.border && 'border-end border-top border-bottom'}`}
            >
                +
            </Button>
        </ButtonGroup>
    );
};
