import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { usePageTracking } from '../../usePageTracking';
import { MainWrapper } from '../../common/components/MainWrapper';
import { EmployeeCard } from '../../common/components/EmployeeCard';
import { Employee } from '../EmployeeList/typings';
import { BusinessIdUrlParam } from '../../common/commonTypings';
import { TabSwitch } from '../../common/components/TabSwitch';
import { PaymentGroupCard } from '../../common/components/PaymentGroupCard';
import { BackButtonWrapper } from '../../common/components/BackButtonWrapper';
import { paymentMethodGroups } from './typings';
import './styles.scss';

interface PayerState {
    employee: Employee;
}

export const PaymentDetailsPage = (): JSX.Element => {
    usePageTracking();
    const { state } = useLocation<PayerState>();
    const { businessId } = useParams<BusinessIdUrlParam>();
    const history = useHistory();
    const [employee, setEmployee] = useState<Employee>({} as Employee);
    const [tipValue, setTipValue] = useState<number>(10.0);
    const [selectedGroup, setSelectedGroup] = useState<string>('');

    useEffect(() => {
        setEmployee(state.employee);
    });

    const onSubmit = () => {
        history.push({
            pathname: `/${businessId}/payerDetails`,
            state: {
                employee,
                tipValue,
                paymentGroup: selectedGroup,
            },
        });
    };

    const isButtonDisabled = tipValue === 0.0 || !tipValue || selectedGroup === '';

    return (
        <MainWrapper viewType="tips" businessId={businessId}>
            <TabSwitch tabActive="tips" />

            <BackButtonWrapper>
                <EmployeeCard className="employeeCardFlex" employee={state.employee} />
            </BackButtonWrapper>
            <p className="paragraph">Podaj kwotę napiwku i wybierz metodę płatności:</p>
            <Form>
                <Form.Group className="mb-4 px-4 value-wrapper" controlId="tipValue">
                    <NumberFormat
                        className="value-input"
                        thousandsGroupStyle="thousand"
                        value={tipValue}
                        suffix=" zł"
                        decimalSeparator=","
                        allowedDecimalSeparators={[',', '.']}
                        displayType="input"
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="tel"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTipValue(parseInt(e.target.value, 10))
                        }
                    />
                </Form.Group>
                {paymentMethodGroups.map(group => (
                    <PaymentGroupCard
                        key={group.id}
                        image={group.img}
                        name={group.name}
                        selected={group.id === selectedGroup}
                        onClick={() => setSelectedGroup(group.id)}
                    />
                ))}
                <div className="d-grid mt-5 position-sticky fixed-bottom mb-5 button-wrapper">
                    <Button
                        className="submit-button mx-4"
                        onClick={onSubmit}
                        disabled={isButtonDisabled}
                    >
                        Przejdź dalej
                    </Button>
                </div>
            </Form>
        </MainWrapper>
    );
};
