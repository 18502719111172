import { Business, BusinessId, BusinessResponse } from '../commonTypings';
import { apiEndpoints } from '../../settings';
import { apiClient } from './apiClient';

interface BusinessInfoServices {
    getBusinessDetails: (businessId: BusinessId) => Promise<Business>;
}

export const useBusinessInfoServices = (): BusinessInfoServices => {
    const transformResponseToBusiness = (businessResponse: BusinessResponse): Business => ({
        id: businessResponse.id,
        name: businessResponse.name,
        city: businessResponse.address.city,
        streetName: businessResponse.address.street_name,
        streetNumber: businessResponse.address.street_number,
        apartmentNumber: businessResponse.address.apartment_number,
        photo: businessResponse.photo,
        billing: businessResponse.billing,
        isTableNumberRequired: businessResponse.is_table_number_required,
    });

    const getBusinessDetails = (businessId: BusinessId): Promise<Business> =>
        apiClient
            .get<BusinessResponse>(apiEndpoints.business(businessId))
            .then(({ data }) => transformResponseToBusiness(data));

    return { getBusinessDetails };
};
