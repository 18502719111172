import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';
import { MainWrapper } from '../../common/components/MainWrapper';
import { BusinessIdUrlParam } from '../../common/commonTypings';
import { BackButtonWrapper } from '../../common/components/BackButtonWrapper';
import { paymentMethodGroups } from '../PaymentDetails/typings';
import { PaymentGroupCard } from '../../common/components/PaymentGroupCard';

export const CartPaymentDetails = (): JSX.Element => {
    const { businessId } = useParams<BusinessIdUrlParam>();
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const history = useHistory();

    const onSubmit = () => {
        history.push({
            pathname: `/${businessId}/cartPayerDetails`,
            state: {
                paymentGroup: selectedGroup,
            },
        });
    };

    const isButtonDisabled = selectedGroup === '';

    return (
        <MainWrapper viewType="tips" businessId={businessId}>
            <BackButtonWrapper />
            <Stack>
                <div className="px-3 pb-3">Wybierz metodę płatności</div>
                {paymentMethodGroups.map(group => (
                    <PaymentGroupCard
                        key={group.id}
                        image={group.img}
                        name={group.name}
                        selected={group.id === selectedGroup}
                        onClick={() => setSelectedGroup(group.id)}
                    />
                ))}
                <div className="d-grid mt-5 position-sticky fixed-bottom mb-5 pb-3">
                    <Button
                        className="submit-button mx-4"
                        onClick={onSubmit}
                        disabled={isButtonDisabled}
                    >
                        Przejdź dalej
                    </Button>
                </div>
            </Stack>
        </MainWrapper>
    );
};
