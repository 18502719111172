import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import tipIconColor from '../../../assets/tip-icon-color.svg';
import tipIcon from '../../../assets/tip-icon.svg';
import bookIconColor from '../../../assets/book-open-color-icon.svg';
import bookIcon from '../../../assets/book-open-icon.svg';
import './styles.scss';

interface TabSwitchProps {
    tabActive?: 'tips' | 'menu';
}

export const TabSwitch = ({ tabActive }: TabSwitchProps): JSX.Element => {
    const history = useHistory();
    const isTipsTabActive = tabActive === 'tips';
    const isMenuTabActive = tabActive === 'menu';
    const redirectToMenu = () => {
        if (isTipsTabActive || (!isTipsTabActive && !isMenuTabActive)) {
            history.push(`menu`);
        }
    };

    const redirectToTips = () => {
        if (isMenuTabActive || (!isTipsTabActive && !isMenuTabActive)) {
            history.push(`employees`);
        }
    };

    return (
        <Card className="mx-3 shadow mb-3 tabSwitchCard" border="light">
            <Card.Body>
                <Container>
                    <Row>
                        <Col xs={6} className="ps-0 tabColumn">
                            <button
                                className={`tabButton ${!isTipsTabActive ? 'tabSpanInactive' : ''}`}
                                onClick={redirectToTips}
                            >
                                <img
                                    src={isTipsTabActive ? tipIconColor : tipIcon}
                                    alt="tipIcon"
                                    className="tabIcon"
                                />
                                <span
                                    className={`tabText ${
                                        isTipsTabActive ? 'tabTextActive' : 'tabTextInactive'
                                    }`}
                                >
                                    Zostaw napiwek
                                </span>
                            </button>
                            <hr className={`tabHr ${!isTipsTabActive ? 'tabHrInactive' : ''}`} />
                        </Col>
                        <Col xs={6} className="ps-0 tabColumn">
                            <button
                                className={`tabButton ${!isMenuTabActive ? 'tabSpanInactive' : ''}`}
                                onClick={redirectToMenu}
                            >
                                <img
                                    src={isMenuTabActive ? bookIconColor : bookIcon}
                                    alt="tipIcon"
                                    className="tabIcon"
                                />
                                <span
                                    className={`tabText ${
                                        isMenuTabActive ? 'tabTextActive' : 'tabTextInactive'
                                    }`}
                                >
                                    Menu
                                </span>
                            </button>
                            <hr className={`tabHr ${!isMenuTabActive ? 'tabHrInactive' : ''}`} />
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};
