import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Stack } from 'react-bootstrap';
import { MainWrapper } from '../../common/components/MainWrapper';
import './styles.css';
import Tick from '../../assets/tick.svg';
import { usePageTracking } from '../../usePageTracking';
import { TRANSACTION_ID } from '../../common/commonTypings';
import { CommentData } from './typing';
import { useThankYouServices } from './thankYouServices';

export const ThankYouPage = (): JSX.Element => {
    usePageTracking();
    const history = useHistory();
    const [comment, setComment] = useState<CommentData>({});
    const [validated, setValidated] = useState<boolean>(false);
    const { leaveComment } = useThankYouServices();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        const form = event.currentTarget;
        event.preventDefault();
        if (!form.checkValidity()) {
            setValidated(true);
        } else {
            const orderId = localStorage.getItem(TRANSACTION_ID);
            localStorage.removeItem(TRANSACTION_ID);
            if (orderId && comment) {
                leaveComment({
                    orderId,
                    comment: comment.text,
                    user_rating: comment.userRating,
                    giveaway_participation_consent: comment.giveawayParticipationConsent,
                });
            }
            history.push('employees');
        }
    };

    return (
        <MainWrapper>
            <Stack className="thankYouContainer mb-5">
                <p className="thankYouTitle">Płatność została wykonana poprawnie!</p>
                <img src={Tick} alt="tick" />
                <p className="thankYouText">Napiwek dotarł do odbiorcy. Dziękujemy!</p>
                <p className="thankYouDescription">
                    Jeżeli chcesz możesz również <br /> zostawić komentarz.
                </p>
            </Stack>
            <Form noValidate onSubmit={handleSubmit} validated={validated}>
                <Form.Group className="px-3 mb-3" controlId="comment">
                    <Form.Label>Treść komentarza:</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setComment({ ...comment, text: e.target.value })
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        To pole nie może być puste.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className=" px-5 pt-5 button-wrapper">
                    <FormGroup className="mb-4 px-2" controlId="button">
                        <Button type="submit" className="thank-you-page-button">
                            {comment.text ? 'Zapisz komentarz i wróć' : 'Wróć do restauracji'}
                        </Button>
                    </FormGroup>
                </div>
            </Form>
        </MainWrapper>
    );
};
