import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePageTracking } from '../../usePageTracking';
import { AccountActivationUrlParams } from './typings';
import { useActivateAccountServices } from './activateAccountServices';

export const ActivateAccountPage = (): JSX.Element => {
    usePageTracking();
    const { token, uid } = useParams<AccountActivationUrlParams>();
    const { activateAccount } = useActivateAccountServices();

    useEffect(() => {
        activateAccount({ token, uid });
    }, [token, uid, activateAccount]);

    return <></>;
};
