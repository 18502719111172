import React from 'react';

import { Row } from 'react-bootstrap';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { BaseMenuItemCard } from '../BaseMenuItemCard';

interface MenuItemCardProps {
    businessId: string;
    id: string;
    photo?: string;
    name: string;
    description?: string;
    price: number;
    billing: boolean;
}

export const MenuItemCard = (props: MenuItemCardProps): JSX.Element => {
    const history = useHistory();
    const onRedirect = (): void => {
        history.push({
            pathname: `/menuItem`,
            state: {
                businessId: props.businessId,
                billing: props.billing,
                id: props.id,
                name: props.name,
                description: props.description,
                price: props.price,
                count: 1,
                photo: props.photo,
            },
        });
    };

    return (
        <BaseMenuItemCard onRedirect={onRedirect} photo={props.photo}>
            <Row>
                <span className="menuItemName">{props.name}</span>
            </Row>
            <Row>
                <span className="menuItemDescription">{props.description}</span>
            </Row>
            <Row className="menuItemPrice">
                <span>{props.price} zł</span>
            </Row>
        </BaseMenuItemCard>
    );
};
