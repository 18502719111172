import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import './styles.scss';

interface PaymentGroupCardProps {
    image: string;
    name: string;
    onClick: () => void;
    selected?: boolean;
}

export const PaymentGroupCard = ({
    image,
    name,
    onClick,
    selected = false,
}: PaymentGroupCardProps): JSX.Element => (
    <Card
        className={`mx-3 mb-3 payment-group-card ${selected ? 'shadow' : ''}`}
        border="light"
        onClick={onClick}
    >
        <Container>
            <Row className="payment-group-row">
                <Col xs={4} className="ps-0 align-self-center">
                    <Image className="payment-group-img" src={image} alt="avatar" />
                </Col>
                <Col className="align-self-center group-name-column">{name}</Col>
                <Col xs={2} className="align-self-center dot-column">
                    <span className={selected ? 'dot-active' : 'dot-inactive'} />
                </Col>
            </Row>
        </Container>
    </Card>
);
