import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { TabSwitch } from '../../common/components/TabSwitch';
import { MenuItemCardWithCounter } from '../../common/components/MenuItemCardWithCounter';
import './styles.scss';
import {
    addTableNumber,
    addToCart,
    CartData,
    CartItem,
    getCart,
    removeFromCart,
} from '../../common/services/cartClient';
import { Business, BusinessIdUrlParam } from '../../common/commonTypings';
import { useBusinessInfoServices } from '../../common/services/businessInfoServices';

export const Cart = (): JSX.Element => {
    const history = useHistory();
    const { businessId } = useParams<BusinessIdUrlParam>();
    const [businessInfo, setBusinessInfo] = useState<Business>({} as Business);
    const [cart, setCart] = useState<CartData | undefined>(getCart());
    const onTrashClick = (item: CartItem) => () => setCart(removeFromCart(item.id));
    const onCounterChange = (item: CartItem) => (count: number) => {
        if (cart) {
            setCart(addToCart(cart?.businessId, { ...item, count }));
        }
    };
    const { getBusinessDetails } = useBusinessInfoServices();

    const onTableNumberChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setCart(addTableNumber(e.target.value));

    useEffect(() => {
        getBusinessDetails(businessId).then((business: Business) => setBusinessInfo(business));
    }, []);
    const isDisabled = businessInfo.isTableNumberRequired && !cart?.tableNumber;
    const onClick = () => history.push('cartPaymentDetails');

    return (
        <>
            <div className="cartRoot">
                <div>
                    <div className="mt-3">
                        <TabSwitch />
                    </div>
                    <Container className="pb-3">
                        <Row className="px-3">Twoje</Row>
                        <Row className="px-3 boldText">Zamówienie</Row>
                    </Container>
                    {businessInfo.isTableNumberRequired ? (
                        <Container className="px-3 mb-3 tableNumberRoot">
                            <div className="tableNumberLabel">Podaj numer stolika:</div>
                            <Form.Control
                                className="tableNumberInput mx-3"
                                id="tableNumber"
                                type="string"
                                value={cart?.tableNumber}
                                onChange={onTableNumberChange}
                            />
                        </Container>
                    ) : (
                        <></>
                    )}
                    {cart?.items.map(item => (
                        <MenuItemCardWithCounter
                            key={item.id}
                            businessId={cart?.businessId}
                            item={item}
                            onTrashClick={onTrashClick(item)}
                            onCounterChange={onCounterChange(item)}
                        />
                    ))}
                </div>
                <div className="mb-4 mt-5">
                    <Container>
                        <Row className="cartPayButtonRoot">
                            <Button
                                disabled={isDisabled}
                                className="cartPayButton"
                                onClick={onClick}
                            >
                                Zapłać {cart?.totalPrice}zł
                            </Button>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};
