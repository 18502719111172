import { Stack } from 'react-bootstrap';

import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { EmployeeCard } from '../../common/components/EmployeeCard';
import { MainWrapper } from '../../common/components/MainWrapper';
import { useBusinessInfoServices } from '../../common/services/businessInfoServices';
import { Business, BusinessIdUrlParam } from '../../common/commonTypings';
import { TabSwitch } from '../../common/components/TabSwitch';
import { BusinessCard } from '../../common/components/BusinessCard';
import { usePageTracking } from '../../usePageTracking';
import { useEmployeeListServices } from './employeeListServices';
import { Employee } from './typings';
import './styles.css';

export const EmployeeListPage = (): JSX.Element => {
    usePageTracking();
    const history = useHistory();
    const location = useLocation();

    const { businessId } = useParams<BusinessIdUrlParam>();
    const { getEmployeeList } = useEmployeeListServices();
    const { getBusinessDetails } = useBusinessInfoServices();
    const [employeeList, setEmployeeList] = useState<Employee[]>([]);
    const [businessInfo, setBusinessInfo] = useState<Business>({} as Business);

    const removeBranchIoQueryParamsFromUrl = (): void => {
        const split = location.pathname.split('/employees');
        if (split[1]) {
            history.push(`${split[0]}/employees`);
        }
    };

    useEffect(() => {
        removeBranchIoQueryParamsFromUrl();
    });

    useEffect(() => {
        getBusinessDetails(businessId).then((business: Business) => setBusinessInfo(business));
    }, []);

    useEffect(() => {
        getEmployeeList(businessId).then((list: Employee[]) => {
            if (list.length === 0) {
                history.push('menu');
            }
            setEmployeeList(list);
        });
    }, []);

    return (
        <MainWrapper viewType="tips" businessId={businessId}>
            <BusinessCard businessInfo={businessInfo} />

            <TabSwitch tabActive="tips" />

            <p className="paragraph">Wybierz osobę, której chcesz zostawić napiwek:</p>
            <Stack className="col-md-5 mx-auto">
                {employeeList.map(employee => (
                    <Link
                        key={employee.id}
                        to={{
                            pathname: `/${businessId}/paymentDetails`,
                            state: {
                                employee,
                            },
                        }}
                        className="link"
                    >
                        <EmployeeCard employee={employee} caret className="mb-3" />
                    </Link>
                ))}
            </Stack>
        </MainWrapper>
    );
};
