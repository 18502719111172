import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routes } from './settings';
import { EmployeeListPage } from './pages/EmployeeList';
import { PayerDetailsPage } from './pages/PayerDetails';
import { PaymentDetailsPage } from './pages/PaymentDetails';
import { ThankYouPage } from './pages/ThankYouPage';
import { QrMenuPage } from './pages/QrMenu';
import { ActivateAccountPage } from './pages/ActivateAccount';
import { MenuItemDetailsPage } from './pages/MenuItemDetails';
import { TransactionErrorPage } from './pages/TransactionErrorPage';
import { Cart } from './pages/Cart';
import { CartPaymentDetails } from './pages/CartPaymentDetails';
import { CartPayerDetails } from './pages/CartPayerDetails';
import { ServiceOrderPage } from './pages/ServiceOrder';

export const App = (): JSX.Element => (
    <Router>
        <Switch>
            <Route path={routes.employees} component={EmployeeListPage} />
            <Route path={routes.payerDetails} component={PayerDetailsPage} />
            <Route path={routes.paymentDetails} component={PaymentDetailsPage} />
            <Route exact path={routes.thankYou} component={ThankYouPage} />
            <Route exact path={routes.transactionError} component={TransactionErrorPage} />
            <Route path={routes.qrMenu} component={QrMenuPage} />
            <Route path={routes.menuItemDetails} component={MenuItemDetailsPage} />
            <Route path={routes.activateAccount} component={ActivateAccountPage} />
            <Route path={routes.cart} component={Cart} />
            <Route path={routes.cartPaymentDetails} component={CartPaymentDetails} />
            <Route path={routes.cartPayerDetails} component={CartPayerDetails} />
            <Route path={routes.serviceOrder} component={ServiceOrderPage} />
        </Switch>
    </Router>
);
