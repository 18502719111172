import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import './styles.css';
import { BackButtonWrapper } from '../../common/components/BackButtonWrapper';
import { usePageTracking } from '../../usePageTracking';
import { Counter } from '../../common/components/Counter';
import { addToCart, getCartItemCount } from '../../common/services/cartClient';
import { CartSymbol } from '../../common/components/CartSymbol';

interface MenuItemDetailsState {
    id: string;
    businessId: string;
    name: string;
    description: string;
    price: number;
    count: number;
    photo: string;
    billing: boolean;
}

export const MenuItemDetailsPage = (): JSX.Element => {
    usePageTracking();
    const { state } = useLocation<MenuItemDetailsState>();
    const [item, setItem] = useState<MenuItemDetailsState>({
        ...state,
        count: Math.max(state.count, getCartItemCount(state.id)),
    });
    const history = useHistory();
    const onOrderButtonClick = () => {
        addToCart(item.businessId, item);
        history.goBack();
    };
    const onCounterChange = (count: number) => {
        setItem({
            ...item,
            count,
        });
    };

    const toFixed = (count: number, price: number) => (count * price).toFixed(2);

    return (
        <>
            {state.billing ? <CartSymbol path={`${item.businessId}/cart`} /> : <></>}
            <div className="menuItemDetailRoot">
                <div>
                    <BackButtonWrapper style={{ position: 'absolute' }} />
                    <Image className="menuItemDetailsImg" src={item.photo} />
                    <div className="mx-3 mt-4 menuItemDetailsTitle">{item.name}</div>
                    <div className="mx-3 mt-3 menuItemDetailsDescription">{item.description}</div>
                    {state.billing ? (
                        <Container className="mt-5">
                            <Row>
                                <Col xs={8}>
                                    <Counter start={item.count} shadow onChange={onCounterChange} />
                                </Col>
                                <Col xs={4} className="menuItemPrice mb-0">
                                    {toFixed(item.count, item.price)} zł
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <div className="mt-3 singleMenuItemPrice">{item.price} zł</div>
                    )}
                </div>

                {state.billing && (
                    <Button onClick={onOrderButtonClick} className="orderButton mb-5 mt-5">
                        Zamów
                    </Button>
                )}
            </div>
        </>
    );
};
