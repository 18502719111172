import { Card } from 'react-bootstrap';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';

interface BackButtonWrapperProps {
    children?: JSX.Element;
    style?: React.CSSProperties;
}

export const BackButtonWrapper = ({ children, style }: BackButtonWrapperProps): JSX.Element => {
    const history = useHistory();

    return (
        <div className="flexWrapper" style={style}>
            <Card
                className="mx-3 shadow h-50 mb-3 backButton"
                border="light"
                onClick={() => history.goBack()}
            >
                <i className="bi bi-arrow-left-short" />
            </Card>
            {children}
        </div>
    );
};
