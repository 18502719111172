import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { MainWrapper } from '../../common/components/MainWrapper';
import './styles.css';
import { usePageTracking } from '../../usePageTracking';

export const TransactionErrorPage = (): JSX.Element => {
    usePageTracking();
    const history = useHistory();

    return (
        <MainWrapper>
            <p className="px-4">Wystąpił błąd, przepraszamy!</p>
            <div className="d-grid mt-5 mb-5 px-4 button-wrapper">
                <Button
                    className="transaction-error-page-button"
                    onClick={() => history.push('employees')}
                >
                    Wróć do restauracji
                </Button>
            </div>
        </MainWrapper>
    );
};
