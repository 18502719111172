import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { MainWrapper } from '../../common/components/MainWrapper';
import { EmployeeCard } from '../../common/components/EmployeeCard';
import { Employee } from '../EmployeeList/typings';
import userIcon from '../../assets/user-icon.svg';
import mailIcon from '../../assets/mail-icon.svg';

import './styles.scss';
import { apiEndpoints } from '../../settings';
import { BusinessIdUrlParam } from '../../common/commonTypings';
import { basePath } from '../../common/services/apiClient';
import { TabSwitch } from '../../common/components/TabSwitch';
import { BackButtonWrapper } from '../../common/components/BackButtonWrapper';
import { usePageTracking } from '../../usePageTracking';
import { usePayerDetailsServices } from './payerDetailsServices';
import { PayerInfo } from './typings';

interface EmployeeState {
    employee: Employee;
    tipValue: number;
    paymentGroup: string;
}

export const PayerDetailsPage = (): JSX.Element => {
    usePageTracking();
    const { state } = useLocation<EmployeeState>();
    const { businessId } = useParams<BusinessIdUrlParam>();
    const [employee, setEmployee] = useState<Employee>({} as Employee);
    const [name, setName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [terms, setTerms] = useState<boolean>(false);
    const [consent, setConsent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const { postPaymentDetails } = usePayerDetailsServices();

    const [validated, setValidated] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);

    useEffect(() => {
        setEmployee(state.employee);
    }, []);

    useEffect(() => {
        if (formValid) {
            postPaymentDetails(
                employee.id,
                businessId,
                state.tipValue,
                { name, lastName, email } as PayerInfo,
                parseInt(state.paymentGroup, 10),
            );
        }
    }, [formValid]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
        } else {
            setFormValid(true);
        }

        setValidated(true);
    };

    const isButtonDisabled = name === '' || lastName === '' || email === '' || !terms || !consent;

    return (
        <MainWrapper viewType="tips" businessId={businessId}>
            <TabSwitch tabActive="tips" />

            <BackButtonWrapper>
                <EmployeeCard className="employeeCardFlex" employee={employee} />
            </BackButtonWrapper>
            <p className="paragraph px-4">
                Operator płatności wymaga podania poniższych danych. <br /> Na podany przez Ciebie
                adres email dostarczone zostanie potwierdzenie dokonania płatności. <br /> Twoje
                dane nie zostaną wykorzystane do innych celów niż informowanie Cię o statusie
                płatności.
            </p>
            <Form noValidate onSubmit={handleSubmit} validated={validated}>
                <div className="input-wrapper">
                    <Form.Group className="mb-4 px-4 w-50" controlId="name">
                        <InputGroup>
                            <InputGroup.Text className="payer-input-icon">
                                <img src={userIcon} alt="userIcon" />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                className="payer-input"
                                placeholder="Imię"
                                value={name}
                                required
                                minLength={2}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setName(e.target.value)
                                }
                            />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            Imię musi mieć przynajmniej 2 znaki.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4 px-4 w-50" controlId="lastName">
                        <InputGroup>
                            <InputGroup.Text className="payer-input-icon">
                                <img src={userIcon} alt="userIcon" />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                className="payer-input"
                                placeholder="Nazwisko"
                                required
                                minLength={1}
                                value={lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setLastName(e.target.value)
                                }
                            />
                        </InputGroup>

                        <Form.Control.Feedback type="invalid">
                            Nazwisko musi mieć przynajmniej 2 znaki.
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <Form.Group className="mb-4 px-4" controlId="email">
                    <InputGroup>
                        <InputGroup.Text className="payer-input-icon">
                            <img src={mailIcon} alt="mailIcon" />
                        </InputGroup.Text>
                        <Form.Control
                            className="payer-input"
                            placeholder="Email"
                            required
                            pattern=".+@.+\..+"
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setEmail(e.target.value)
                            }
                        />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                        Podaj prawidłowy adres email.
                    </Form.Control.Feedback>
                </Form.Group>
                <FormGroup className="mb-4 px-4" controlId="terms">
                    <Form.Check
                        type="checkbox"
                        className="checkbox"
                        checked={terms}
                        label={
                            <span>
                                Zapoznałem/am się i akceptuję treść{' '}
                                <a
                                    className="terms-privacy-link"
                                    href={`${basePath}${apiEndpoints.termsAndConditions}`}
                                >
                                    regulaminu
                                </a>{' '}
                                i{' '}
                                <a
                                    className="terms-privacy-link"
                                    href={`${basePath}${apiEndpoints.privacyPolicy}`}
                                >
                                    polityki prywatności
                                </a>
                            </span>
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTerms(e.target.checked)
                        }
                    />
                </FormGroup>
                <FormGroup className="mb-4 px-4" controlId="consent">
                    <Form.Check
                        type="checkbox"
                        className="checkbox"
                        checked={consent}
                        label={
                            <span>
                                Wyrażam zgodę na rozpoczęcie świadczenia dostępu do treści cyfrowych
                                w ramach aplikacji web zgodnie z treścią{' '}
                                <a
                                    className="terms-privacy-link"
                                    href={`${basePath}${apiEndpoints.termsAndConditions}`}
                                >
                                    regulaminu
                                </a>{' '}
                                przed upływem terminu do odstąpienia od Umowy, mając świadomość, że
                                złożenie tego oświadczenia i uzyskanie dostępu do treści cyfrowych
                                spowoduje utratę prawa odstąpienia od Umowy w rozumieniu w/w{' '}
                                <a
                                    className="terms-privacy-link"
                                    href={`${basePath}${apiEndpoints.termsAndConditions}`}
                                >
                                    regulaminu
                                </a>
                                .
                            </span>
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setConsent(e.target.checked)
                        }
                    />
                </FormGroup>

                <FormGroup className="mb-4 px-4" controlId="button">
                    <Button
                        className="submit-button-payer"
                        type="submit"
                        disabled={isButtonDisabled}
                    >
                        Zostaw napiwek
                    </Button>
                </FormGroup>
            </Form>
        </MainWrapper>
    );
};
