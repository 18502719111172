import { Col, Container, Image, Row } from 'react-bootstrap';
import React from 'react';
import { BaseMenuItemCard } from '../BaseMenuItemCard';
import './styles.css';
import { Counter } from '../Counter';
import trashImage from '../../../assets/trash.svg';
import { CartItem } from '../../services/cartClient';

type MenuItemCardWithCounterProps = {
    businessId: string;
    item: CartItem;
    onTrashClick: () => void;
    onCounterChange: (count: number) => void;
};

export const MenuItemCardWithCounter = (props: MenuItemCardWithCounterProps): JSX.Element => (
    <BaseMenuItemCard photo={props.item.photo}>
        <Container>
            <Row className="menuItemName">{props.item.name}</Row>
            <Row className="menuItemCounterPrice mb-1">{props.item.price}zł</Row>
            <Row>
                <Col xs={6} className="p-0">
                    <Counter start={props.item.count} border onChange={props.onCounterChange} />
                </Col>
                <Col xs={6} className="trashContainer">
                    <Image
                        className="border trashImage"
                        src={trashImage}
                        onClick={props.onTrashClick}
                    />
                </Col>
            </Row>
        </Container>
    </BaseMenuItemCard>
);
