import { apiClient } from '../../common/services/apiClient';
import { apiEndpoints } from '../../settings';
import { CreateServiceOrderRequest, ServiceOrder, ServiceOrderResponse } from './typing';

interface ServiceOrderServices {
    getServiceOrder: (id: string) => Promise<ServiceOrder>;
    createServiceOrder: (data: CreateServiceOrderRequest) => Promise<ServiceOrder>;
}

const zeroPad = (num: number, size: number): string => {
    let result = num.toString();
    while (result.length < size) result = `0${result}`;
    return result;
};

const format = (data: ServiceOrderResponse): ServiceOrder => ({
    id: data.id,
    createdAt: new Date(data.created_at),
    items: data.items,
    preparationStatus: data.preparation_status,
    tableName: data.table_name,
    transactionUrl: data.transaction_url,
    updatedAt: new Date(data.updated_at),
    value: data.value,
    businessId: data.business_id,
    orderNumber: zeroPad(data.order_number, 3),
});

export const useServiceOrderServices = (): ServiceOrderServices => ({
    createServiceOrder: (data: CreateServiceOrderRequest): Promise<ServiceOrder> =>
        apiClient
            .post<ServiceOrderResponse>(apiEndpoints.serviceOrder, data)
            .then(response => response.data)
            .then(format),
    getServiceOrder: (id: string): Promise<ServiceOrder> =>
        apiClient
            .get<ServiceOrderResponse>(apiEndpoints.getMenuOrder(id))
            .then(response => response.data)
            .then(format),
});
