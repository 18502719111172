import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { Business } from '../../commonTypings';
import './styles.css';

interface BusinessCardProps {
    businessInfo: Business;
}

export const BusinessCard = ({ businessInfo }: BusinessCardProps): JSX.Element => {
    const buildBusinessAddress = () => {
        const apartmentNumber = businessInfo.apartmentNumber
            ? `/${businessInfo.apartmentNumber}`
            : '';
        return `${businessInfo.streetName} ${businessInfo.streetNumber}${apartmentNumber}, ${businessInfo.city}`;
    };

    return (
        <Card className="businessCard" border="light">
            <Card.Body className="businessCardBody">
                {businessInfo.photo && (
                    <Image src={businessInfo.photo} className="businessCardPhoto" />
                )}
                <Card.Title className="businessCardTitle">{businessInfo.name}</Card.Title>
                <Card.Text className="businessCardText">{buildBusinessAddress()}</Card.Text>
            </Card.Body>
        </Card>
    );
};
