import React from 'react';

import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Employee } from '../../../pages/EmployeeList/typings';
import './styles.css';

interface EmployeeCardProps {
    employee: Employee;
    caret?: boolean;
    className?: string;
}
export const EmployeeCard = (props: EmployeeCardProps): JSX.Element => (
    <Card className={`mx-3 shadow h-50 employeeCard ${props.className}`} border="light">
        <Container>
            <Row>
                <Col xs={3} className="ps-0 imgColumn">
                    <Image className="img" src={props.employee.avatarPhoto} alt="avatar" />
                </Col>
                <Col className="align-self-center nameColumn">
                    <span className="employeeCardDisplayName">{props.employee.displayName}</span>
                </Col>
                {props.caret && (
                    <Col xs={2} className="align-self-center text-center">
                        <i className="bi bi-chevron-right" />
                    </Col>
                )}
            </Row>
        </Container>
    </Card>
);
