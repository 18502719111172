export const paymentGroups = {
    150: 'BLIK',
    103: 'PAYMENT_CARD',
    113: 'ALIOR_BANK_SA',
    102: 'BANK_PEKAO_SA',
    108: 'PKO_BANK_POLSKI',
    110: 'INTELIGO',
    160: 'MBANK',
    111: 'ING_BANK_SLASKI_SA',
    114: 'BANK_MILLENIUM_SA',
    115: 'SANTANDER_BANK_POLSKA_SA',
    132: 'CITIBANK_HANDLOWY_SA',
    116: 'CREDIT_AGRICOLE_POLSKA_SA',
    119: 'GETIN_BANK_SA',
    124: 'BANK_POCZTOWY_SA',
    135: 'BANKI_SPOLDZIELCZE',
    133: 'BNP_PARIBAS_BANK_POLSKA_SA',
    145: 'PLUS_BANK_SA',
    166: 'GOOGLE_PAY',
};

export interface PaymentRequest {
    employee_id: string;
    business_id: string;
    value: string;
    first_name: string;
    last_name: string;
    email: string;
    payment_method: string;
    redirect_success_url: string;
    redirect_error_url: string;
}

export interface PaymentResponse {
    transaction_url: string;
    id: string;
}

export const paymentMethodGroups = [
    {
        id: '150',
        name: 'BLIK',
        banks: '64,29',
        img: 'https://secure.tpay.com/_/g/150.png?4',
        main_bank_id: '64',
    },
    {
        id: '166',
        name: 'Google Pay',
        banks: '68',
        img: 'https://secure.tpay.com/_/g/166.png?4',
        main_bank_id: '68',
    },
    {
        id: '103',
        name: 'Karta płatnicza',
        banks: '53',
        img: 'https://secure.tpay.com/_/g/103.png?4',
        main_bank_id: '53',
    },
    {
        id: '108',
        name: 'PKO Bank Polski',
        banks: '21,64,29',
        img: 'https://secure.tpay.com/_/g/108.png?4',
        main_bank_id: '21',
    },
    {
        id: '110',
        name: 'Inteligo',
        banks: '14,64,29',
        img: 'https://secure.tpay.com/_/g/110.png?4',
        main_bank_id: '14',
    },
    {
        id: '102',
        name: 'Bank Pekao SA',
        banks: '55,47,4,64,60,29',
        img: 'https://secure.tpay.com/_/g/102.png?4',
        main_bank_id: '4',
    },
    {
        id: '115',
        name: 'Santander Bank Polska SA',
        banks: '6,64,60,29',
        img: 'https://secure.tpay.com/_/g/115.png?4',
        main_bank_id: '6',
    },
    {
        id: '160',
        name: 'mBank',
        banks: '18,64,60,29',
        img: 'https://secure.tpay.com/_/g/160.png?4',
        main_bank_id: '18',
    },
    {
        id: '111',
        name: 'ING Bank Śląski SA',
        banks: '13,64,29',
        img: 'https://secure.tpay.com/_/g/111.png?4',
        main_bank_id: '13',
    },
    {
        id: '113',
        name: 'Alior Bank SA',
        banks: '9,64,29',
        img: 'https://secure.tpay.com/_/g/113.png?4',
        main_bank_id: '9',
    },
    {
        id: '133',
        name: 'BNP Paribas Bank Polska SA',
        banks: '1,64,29',
        img: 'https://secure.tpay.com/_/g/133.png?4',
        main_bank_id: '1',
    },
    {
        id: '114',
        name: 'Bank Millennium SA',
        banks: '48,2,64,60,29',
        img: 'https://secure.tpay.com/_/g/114.png?4',
        main_bank_id: '2',
    },
    {
        id: '116',
        name: 'Credit Agricole Polska SA',
        banks: '17,64,29',
        img: 'https://secure.tpay.com/_/g/116.png?4',
        main_bank_id: '17',
    },
    {
        id: '132',
        name: 'Citibank Handlowy SA',
        banks: '7,29',
        img: 'https://secure.tpay.com/_/g/132.png?4',
        main_bank_id: '7',
    },
    {
        id: '124',
        name: 'Bank Pocztowy SA',
        banks: '5,29',
        img: 'https://secure.tpay.com/_/g/124.png?4',
        main_bank_id: '5',
    },
    {
        id: '135',
        name: 'Banki Spółdzielcze',
        banks: '63,42,28,29',
        img: 'https://secure.tpay.com/_/g/135.png?4',
        main_bank_id: '63',
    },
    {
        id: '119',
        name: 'Getin Bank SA',
        banks: '12,64,60,29',
        img: 'https://secure.tpay.com/_/g/119.png?4',
        main_bank_id: '12',
    },
    {
        id: '145',
        name: 'Plus Bank SA',
        banks: '15,60,29',
        img: 'https://secure.tpay.com/_/g/145.png?4',
        main_bank_id: '15',
    },
];
