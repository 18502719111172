import React, { useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useParams } from 'react-router-dom';
import { usePageTracking } from '../../usePageTracking';
import { MainWrapper } from '../../common/components/MainWrapper';
import { MenuItemCard } from '../../common/components/MenuItemCard';
import { CategoryChip } from '../../common/components/CategoryChip';
import { useBusinessInfoServices } from '../../common/services/businessInfoServices';
import { Business } from '../../common/commonTypings';
import { TabSwitch } from '../../common/components/TabSwitch';
import { BusinessCard } from '../../common/components/BusinessCard';
import { CartSymbol } from '../../common/components/CartSymbol';
import { useQrMenuServices } from './qrMenuServices';
import { MenuCategory, QrMenuPageUrlParams } from './typings';
import './styles.css';

export const QrMenuPage = (): JSX.Element => {
    usePageTracking();
    const { businessId } = useParams<QrMenuPageUrlParams>();
    const [categories, setCategories] = useState<MenuCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<MenuCategory>({} as MenuCategory);
    const { getCategories } = useQrMenuServices();
    const { getBusinessDetails } = useBusinessInfoServices();
    const [businessInfo, setBusinessInfo] = useState<Business>({} as Business);

    useEffect(() => {
        getBusinessDetails(businessId).then((business: Business) => setBusinessInfo(business));
    }, []);

    useEffect(() => {
        getCategories(businessId).then(menuCategories =>
            setCategories(menuCategories.filter(category => category.items.length > 0)),
        );
    }, []);

    type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
    const onWheel = (apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void => {
        const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isThouchpad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY < 0) {
            apiObj.scrollNext();
        } else if (ev.deltaY > 0) {
            apiObj.scrollPrev();
        }
    };

    return (
        <MainWrapper viewType="menu" businessId={businessId}>
            {businessInfo.billing ? <CartSymbol path="cart" /> : <></>}
            <BusinessCard businessInfo={businessInfo} />
            <TabSwitch tabActive="menu" />

            <div>
                <ScrollMenu onWheel={onWheel} wrapperClassName="scrollMenuWrapper">
                    {categories.map(category => (
                        <CategoryChip
                            title={category.name}
                            amount={category.items.length}
                            itemId={category.name}
                            key={category.name}
                            selected={selectedCategory.id === category.id}
                            onClick={(_: React.MouseEvent<HTMLElement>) =>
                                selectedCategory.id === category.id
                                    ? setSelectedCategory({} as MenuCategory)
                                    : setSelectedCategory(category)
                            }
                        />
                    ))}
                </ScrollMenu>
                {categories
                    .filter(category => {
                        if (Object.keys(selectedCategory).length === 0) {
                            return true;
                        }
                        return category.id === selectedCategory.id;
                    })
                    .map(category => (
                        <div key={category.id} className="mb-3">
                            <div className="marginLeft categoryName">
                                <strong>{category.name}</strong> ({category.items.length})
                            </div>
                            {category.items.map(menuItem => (
                                <MenuItemCard
                                    key={menuItem.id}
                                    id={menuItem.id}
                                    name={menuItem.name}
                                    description={menuItem.description}
                                    photo={menuItem.photo}
                                    price={menuItem.price}
                                    businessId={businessId}
                                    billing={businessInfo.billing}
                                />
                            ))}
                        </div>
                    ))}
            </div>
        </MainWrapper>
    );
};
