import { Card, Container, Image } from 'react-bootstrap';
import React from 'react';
import './styles.css';
import servicePhoto from '../../../assets/service-placeholder.svg';

type BaseMenuItemProps = {
    photo?: string;
    onRedirect?: () => void;
    children: JSX.Element | JSX.Element[];
};

export const BaseMenuItemCard = (props: BaseMenuItemProps): JSX.Element => {
    const photo = props.photo ?? servicePhoto;
    return (
        <Card className="mx-3 shadow mb-3 menuItemCard" border="light" onClick={props.onRedirect}>
            <Image className="img" src={photo} alt="menuItem" />
            <Container className="menuItemColumn">{props.children}</Container>
        </Card>
    );
};
