import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SERVICE_ORDER_ID } from '../../common/commonTypings';
import { TabSwitch } from '../../common/components/TabSwitch';
import { useServiceOrderServices } from './ServiceOrderServices';
import { ServiceOrder } from './typing';
import './styles.scss';
import { PreparationStatus } from './PreparationStatus';
import { useInterval } from './interval';

export const ServiceOrderPage = (): JSX.Element => {
    const orderId = localStorage.getItem(SERVICE_ORDER_ID) ?? '';
    const { getServiceOrder } = useServiceOrderServices();
    const [serviceOrder, setServiceOrder] = useState<ServiceOrder | undefined>();
    const history = useHistory();

    useEffect(() => {
        getServiceOrder(orderId).then(setServiceOrder);
    }, []);
    useInterval(() => getServiceOrder(orderId).then(setServiceOrder), 5000);
    const onClick = () => history.push(`/${serviceOrder?.businessId}/menu`);
    return (
        <div className="serviceOrderRoot pt-3 pb-3">
            <div>
                <TabSwitch />
                <div className="pt-3 px-3">Status</div>
                <div className="px-3 boldText">zamówienia</div>

                <div className="pt-5 px-3 mb-2 boldText">Status</div>
                <div className="px-5">
                    <PreparationStatus status={serviceOrder?.preparationStatus} />
                </div>

                <div className="mt-5 px-3 boldText mb-2">Zamówienie</div>
                <div className="px-5 mb-3">Numer zamówienia: {serviceOrder?.orderNumber}</div>
                {serviceOrder?.items.map((item, idx) => (
                    <div className="px-5" key={idx}>
                        {item.quantity}x {item.name}
                    </div>
                ))}
            </div>

            <Container>
                <Row className="backButtonRoot">
                    <Button onClick={onClick} className="backServiceOrderButton">
                        Wróć do aplikacji
                    </Button>
                </Row>
            </Container>
        </div>
    );
};
