export type BusinessId = string;
export type OrderId = string;

export interface BusinessResponse {
    id: string;
    name: string;
    description: string;
    address: {
        city: string;
        street_name: string;
        street_number: number;
        apartment_number?: number;
        zip_code: string;
    };
    photo?: string;
    billing: boolean;
    is_table_number_required: boolean;
}

export interface Business {
    id: string;
    name: string;
    city: string;
    streetName: string;
    streetNumber: number;
    apartmentNumber?: number;
    photo?: string;
    billing: boolean;
    isTableNumberRequired: boolean;
}

export interface BusinessIdUrlParam {
    businessId: string;
}

export const TRANSACTION_ID = 'TRANSACTION_ID';
export const SERVICE_ORDER_ID = 'MENU_TRANSACTION_ID';
