import axios from 'axios';
import { toast } from 'react-toastify';

export const basePath = process.env.REACT_APP_API_URL;

export const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(
    config => {
        // eslint-disable-next-line no-param-reassign
        config.url = `${basePath}${config.url}`;
        return config;
    },
    error => Promise.reject(error),
);

apiClient.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            toast('Network error.');
            // eslint-disable-next-line no-console
            console.error(error);
        } else {
            const {
                response: { status, data },
            } = error;

            if (status === 400) {
                if (typeof data === 'object') {
                    const messages = Object.values<Record<string, string>>(data);

                    toast.warn(messages?.[0]?.[0]);
                }
                if (typeof data === 'string') {
                    toast.warn(data);
                }
            } else if (status === 403) {
                toast.error('Nie masz uprawnień do tego zasobu.');
            } else if (status === 404) {
                toast.warn('Nie znaleziono szukanego zasobu.');
            } else if (status === 409) {
                toast.warn('Taki zasób już istnieje.');
            } else if (status === 500) {
                toast.error('Wystąpił błąd. Skontaktuj się z zespołem Servala');
            }
        }

        return Promise.reject(error?.response);
    },
);
