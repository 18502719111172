import { apiClient } from '../../common/services/apiClient';
import { apiEndpoints, routes } from '../../settings';
import { BusinessId, TRANSACTION_ID } from '../../common/commonTypings';
import { paymentGroups, PaymentRequest, PaymentResponse } from '../PaymentDetails/typings';
import { PayerInfo } from './typings';

interface PayerDetailsServices {
    postPaymentDetails: (
        employeeId: string,
        businessId: BusinessId,
        tipValue: number,
        payerInfo: PayerInfo,
        paymentMethodGroupId: number,
    ) => Promise<void>;
}

export const usePayerDetailsServices = (): PayerDetailsServices => {
    const postPaymentDetails = (
        employeeId: string,
        businessId: BusinessId,
        tipValue: number,
        payerInfo: PayerInfo,
        paymentMethodGroupId: number,
    ): Promise<void> => {
        const paymentRequest: PaymentRequest = {
            employee_id: employeeId,
            business_id: businessId,
            value: tipValue.toString(),
            first_name: payerInfo.name,
            last_name: payerInfo.lastName,
            email: payerInfo.email,
            // @ts-ignore
            payment_method: paymentGroups[paymentMethodGroupId],
            redirect_success_url: `${process.env.REACT_APP_URL}${routes.thankYou.replace(
                ':businessId',
                businessId,
            )}`,
            redirect_error_url: `${process.env.REACT_APP_URL}${routes.transactionError.replace(
                ':businessId',
                businessId,
            )}`,
        };
        return apiClient
            .post<PaymentResponse>(apiEndpoints.payment, { ...paymentRequest })
            .then(response => response.data)
            .then(data => {
                localStorage.setItem(TRANSACTION_ID, data.id);
                // eslint-disable-next-line no-restricted-globals
                location.href = data.transaction_url;
            });
    };

    return { postPaymentDetails };
};
